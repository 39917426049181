<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card>
        <b-form @keyup.enter.prevent="" @submit.prevent="submitNewStock">
          <b-row>
            <b-col cols="12">
              <h4>Create Stock Initialization</h4>
              <br />
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <br />
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Branch"
                label-for="branch"
                label-cols-md="12"
              >
                <b-form-input
                  id="ChosenBranch"
                  v-model="chosenBranch"
                  autofocus
                  trim
                  readonly
                />
              </b-form-group>
              <b-form-group label="Items *" label-for="chosenItem">
                <Select2 v-model="chosenItem" :settings="settings" @select="putItem($event)" />
              </b-form-group>
              <b-row>
                <b-col cols="7" class="text-center font-weight-bold">
                  Item name
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Item QTY </b-col
                ><b-col cols="2" class="text-center font-weight-bold">
                  Item Shelves
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  Actions
                </b-col>
              </b-row>
              <p />
              <b-row v-for="chosen in chosens" :key="chosen.value">
                <br />
                <b-col cols="7 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                    v-model="chosen.qty"
                    type="number"
                    placeholder="Qty"
                    min="1"
                    required="required"
                  />
                </b-col>
                <b-col cols="2 text-center">
                  <v-select-new v-model="chosen.shelf" :options="shelves" required="required"/>
                </b-col>
                <b-col cols="1 text-center">
                  <b-button
                    size="sm"
                    type="button"
                    variant="danger"
                    class="mr-1"
                    @click="removeRow(chosen.id)"
                  >
                    <feather-icon icon="TrashIcon" size="18" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="12">
              <b-button type="submit" variant="primary" class="mr-1"
              :disabled="submitButtonDisabled">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BFormFile,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import Select2 from 'v-select2-component'

const VersionCheck = ""
const items = []
const types = []
const branches = []
const chosenItem = ""
const chosens = []
const chosenBranch = ""
const chosenStockType = "IN"
const stockTypes = ["IN"]
const shelves = []
const submitButtonDisabled = false
export default {
  components: {
    BFormFile,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    Select2,
  },
  directives: {
    Ripple,
  },
  data() {
    const userToken = this.$cookies.get("userToken")
    return {
      submitButtonDisabled,
      shelves,
      stockTypes,
      chosenStockType,
      branches,
      chosenBranch,
      VersionCheck,
      items,
      types,
      chosenItem,
      chosens,
      settings: {
        ajax: {
          url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`,
          beforeSend: xhr => {
            xhr.setRequestHeader('Authorization', `Bearer ${userToken}`)
          },
          data: params => {
            const query = {
              search: params.term,
              offset: 0,
              limit: 50,
            }

            // Query parameters will be ?search=[term]&type=public
            return query
          },
          processResults: response => {
            let resItems = response.data
            resItems = resItems.map(item => {
              item.text = `${item.item_code} - ${item.item_name}`
              item.id = item.item_id
              return item
            })
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: resItems,
            }
          },
        },
      },
    }
  },
  mounted() {
    this.chosens = []
    this.getDetails()
    // this.getItems()
    this.getBranches()
    const defaultTypes = [
      { label: "Head Office", value: "true" },
      { label: "Cabang", value: "false" },
    ]
    this.types = defaultTypes
    const shelfsVariables = process.env.VUE_APP_SHELF_LIST.split(",")
    const shelfsRepeat = process.env.VUE_APP_SHELF_REPEAT.split(",")
    this.shelves = []
    shelfsVariables.map(s => {
      shelfsRepeat.map(r => {
        this.shelves.push(`${s}${r}`)
      })
    })
  },
  methods: {
    myChangeEvent(val) {
      console.log(val)
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text })
    },
    getDetails() {
      const dataDetails = this.$cookies.get("BranchDetail")
      this.chosenBranch = `${dataDetails.branch_name} (${dataDetails.branch_code})`
    },
    getBranches() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push({
                label: `${elem.branch_name} (${elem.branch_code})`,
                value: elem.branch_id,
              })
            })
            this.branches = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Branches Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Branches Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getItems(search = "") {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}?offset=0&limit=50search=${search}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = response.data.data.map(elem => ({
              text: `${elem.item_code} - ${elem.item_name}`,
              id: elem.item_id,
            }))
            this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    submitNewStock() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const dataDetails = this.$cookies.get("BranchDetail")
      const branchId = dataDetails.branch_id
      this.chosens.map(elem => {
        this.materialRequestQtyRequested += parseInt(elem.qty)
      })
      if (this.chosenStockType !== "" && this.chosens.length > 0) {
      const body = {
        stock_branch_id: branchId,
        stock_items: JSON.stringify(this.chosens),
        stock_type: this.chosenStockType,
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_STOCKS}`,
          body,
          { headers },
        )
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.materialRequestQtyRequested = 0
            this.materialRequestReason = ""
            this.materialRequestTo = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Create Stock Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.$router.replace({ name: "apps-item-stocks-list" })
          } else {
            if (typeof response.data.data !== 'undefined') {
            const messages = Object.values(response.data.data).map(obj => obj.message)
            const friendlyMessages = messages.join('\n')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: friendlyMessages ? friendlyMessages : response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
          }
        })
      } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please fill up the form",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
      }
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem({ id, text }) {
      console.log(`SELECT ${id} - ${text}`)
      if (this.chosens.length > 0) {
        const item = {
          label: text,
          value: id,
          qty: 1,
          id: this.chosens.length + 1,
          shelf: "",
        }
        this.chosens.push(item)
        // }
      } else {
        const item = {
          label: text,
          value: id,
          qty: 1,
          id: this.chosens.length,
          shelf: "",
        }

        this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.id !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    previous() {
      this.$router.push({ name: "apps-item-stocks-details" })
    },
  },
}
</script>
